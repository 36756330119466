html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    height: 100%;
    margin: 0;
    padding: 0;
    /* background-image: url('/public/assets/images/background.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat; */
}

#root,
.App {
    height: 100%;
}

#root {
    /* display: flex; */
    flex-direction: column;
}

.main-conainer {
    height: 100%;
    backdrop-filter: blur(7px);
    background: #ffffff8c;
    box-shadow:
        1.9px 1.2px 4.6px rgba(0, 0, 0, 0.024),
        5.3px 3.4px 12.8px rgba(0, 0, 0, 0.035),
        12.7px 8.1px 30.8px rgba(0, 0, 0, 0.046),
        42px 27px 102px rgba(0, 0, 0, 0.07);
}

.form {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.form-header {
    padding: 20px 0px;
}

.form-content {
    flex-grow: 1;
}

.form-footer {
    padding: 20px 0px;
}